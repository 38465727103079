<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="contents-header">
      <div class="separator">
        <p class="breadcrumb-menu">
          <router-link :to="{ name: 'DBLogs', query: $route.query }" class="ashen-link">ログ管理</router-link>
          <i class="fas fa-angle-right form-control-color"></i> ログ詳細
        </p>
      </div>
      <div class="card border-0 text-first mt-3">
        <div class="row d-flex justify-content-between">
          <div v-if="prevLogString" class="col-6">
            <router-link :to="linkPrevLog" class="ashen-link mx-1" replace>
              <i class="fas fa-angle-left"></i>
              {{ prevLogString }}
            </router-link>
          </div>
          <div v-else class="col-6"></div>
          <div v-if="nextLogString" class="col-6 text-end">
            <router-link :to="linkNextLog" class="ashen-link mx-1" replace>
              {{ nextLogString }}<i class="fas fa-angle-right mx-1"></i>
            </router-link>
          </div>
          <div v-else class="col-6"></div>
        </div>
      </div>
    </div>
    <div class="card border-0 text-first mt-3">
      <div class="card-body mt-4 mb-4 px-5">
        <div class="row">
          <div class="col">
            <label for="exampleInputPassword1">操作日時</label>
            <input :value="getDateTimeFormat(log.dateTime)" class="form-control half-width-input mt-1" disabled
                   type="text">
            <div v-show="errors['dateTime']" class="form-error">{{ errors['dateTime'] }}</div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">操作</label>
            <input v-model="log.operation" class="form-control half-width-input mt-1" disabled type="text">
            <!--                        <div v-show="errors['dateTime']" class="form-error">{{ errors['dateTime'] }}</div>-->
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">姓　名　入場管理番号</label>
            <input v-model="log.lastName" class="form-control half-width-input" disabled type="text">
            <!--                        <div v-show="errors['lastName']" class="form-error">{{ errors['lastName'] }}</div>-->
          </div>
          <div class="col">
            <label for="exampleInputPassword1"></label>
            <input v-model="log.firstName" class="form-control half-width-input" disabled type="text">
            <!--                        <div v-show="errors['firstName']" class="form-error">{{ errors['firstName'] }}</div>-->
          </div>
          <div class="col">
            <label for="exampleInputPassword1"></label>
            <input v-model="log.userId" class="form-control half-width-input" disabled type="text">
            <!--                        <div v-show="errors['userId']" class="form-error">{{ errors['userId'] }}</div>-->
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">対象</label>
            <input v-model="log.target" class="form-control half-width-input mt-1" disabled type="text">
            <!--                        <div v-show="errors['dateTime']" class="form-error">{{ errors['dateTime'] }}</div>-->
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="contents">内容</label>
            <textarea id="contents"
                      v-model="logContents"
                      class="form-control"
                      disabled
                      rows="10"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Common from "../assets/jsadminclient/common";
import Helper from "../assets/jsadminclient/helper";
import {mapGetters} from "vuex";

export default {
  name: "DBLogDetail",
  props: {
    logId: {type: Number, default: null},
    defaultResponse: {type: Object, default: null},
  },
  data() {
    return {
      loading: true,
      nextUserId: '',
      nextUserName: '',
      prevUserId: '',
      prevUserName: '',
      nextLogId: '',
      prevLogId: '',
      log: {},
      logContents: '',
      reloadKey: 1,
      errors: []
    };
  },
  computed: {
    ...mapGetters(["config"]),
    prevLogString() {
      return this.prevUserName ? this.prevUserName + '(' + this.prevUserId + ')' : ''
    },
    nextLogString() {
      return this.nextUserName ? this.nextUserName + '(' + this.nextUserId + ')' : ''
    },
    linkPrevLog() {
      return {
        name: "DBLogDetail",
        params: {
          logId: this.prevLogId ? Number(this.prevLogId) : this.prevLogId,
        },
        query: this.createSearchQuery()
      }
    },
    linkNextLog() {
      return {
        name: "DBLogDetail",
        params: {
          logId: this.nextLogId ? Number(this.nextLogId) : this.nextLogId,
        },
        query: this.createSearchQuery()
      }
    }
  },
  watch: {
    $route(to, from) {
      // Called from back or next
      this.getLog()
    }
  },
  mounted() {
    this.initialPageLoadHandler()
  },
  methods: {
    initialPageLoadHandler() {
      this.getLog()
    },
    getDateTimeFormat(dtm) {
      return moment(dtm).format('YYYY/MM/DD  HH:mm')
    },
    backToLog() {
      this.$router.push(backToRoute);
    },
    backToRoute() {
      return {
        name: "DBLogs",
        query: this.$route.query
      }
    },
    async getLog() {
      try {
        const api = new AdminApi.JTALogApi(Helper.apiConfig(this.config.token));
        this.log = await api.jtaLogsLogIdGetAsync(
            this.logId,
            this.$route.query.startAt,
            this.$route.query.endAt,
            this.$route.query.startTime,
            this.$route.query.endTime,
            this.$route.query.keyword,
            this.$route.query.operation,
            this.$route.query.sort,
            this.$route.query.order
        );
        this.logId = this.log.logId
        this.nextUserId = this.log.nextUserId
        this.nextUserName = this.log.nextUserName
        this.prevUserId = this.log.prevUserId
        this.prevUserName = this.log.prevUserName
        this.prevLogId = this.log.prevLogId
        this.nextLogId = this.log.nextLogId
        this.logContents = JSON.stringify(JSON.parse(this.log.contents), null, '  ');
      } catch (e) {
        this.log = [];
        this.logContents = '';
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    createSearchQuery() {
      return Common.appendSearchQuery(this.$route.query)
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  font-size: 16px;
}

.delete {
  width: 160px;
  height: 44px;
}

.form-check {
  padding-left: 33px;
}


.btn-file {
  position: relative;
  overflow: hidden;
  border: 1px solid #f2ecf0;
  height: 48px;
  line-height: 30px;
  border-radius: 0px;
}

.fa-upload {
  color: #f2ecf0;
}

.button-container {
  padding: 0px 190px;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: block;
}

.form-control {
  background: #f2ecf0;
  border: none;
}

::placeholder {
  color: #888888;
}

.card-container {
  padding: 7rem !important;
  padding-top: 2rem !important;
}

a {
  color: #333333;
}

.filter-select,
.filter-search-form,
.btn-search {
  font-size: 13px;
  height: 32px !important;
  line-height: 13px;
}

.filter-search-form {
  border: 1px solid #dee2e6;
}

.btn-search {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.content {
  &.touradminsip {
    .custom-button {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }

    .custom-button-outline.btn {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }
  }
}

textarea.form-control {
  min-height: calc(240px) !important;
  resize: none;
}

</style>